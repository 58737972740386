@import "./theme/variables.scss";
.container{
    padding-right: 15px;
    // padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: $secondaryColor;
    &::after{
      content: '';
      position: absolute;
      width: 100%;
      //transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $secondaryColor;
      animation: 1s ease-out 0s 1 slideInFromLeft;
      // transform: scaleX(1);
    }
  }
  .hover-secondary-underline-animation {
    display: inline-block;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      width: 100%;
      //transform: scaleX(0);
      margin-top: 5px;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: #9E9E9E;
      animation: 1s ease-out 0s 1 slideInFromLeft;
      // transform: scaleX(1);
    }
  }
  @keyframes slideInFromLeft {
    0% {
      opacity: 1;
    transform: scale(0);
    transform-origin: center;
    }
    100% {
      transform: scale(1);
    }
  }
// @media screen and (max-width: $breakPointTablet) {
//     .pages{
//         height: calc(100vh - 13.5rem);
//     }
//   }
//   @media screen and (max-width: $breakPointMobile) {
//     .pages{
//         height: calc(100vh - 19.5rem);
//     }
//   }